
import React from 'react';
import $ from 'jquery';
import _ from 'underscore';

// Libs
var ApiClient = require('common/libs/api_client');

const camelCaseToDash = function(str) {
  return str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase()
}

export default class App extends React.Component {
	
	constructor(props){
		super(props);
		this.state = {
			
			is_requesting_password : false,
			
			request_login_error : '',
			request_login_success : false,
			
			login_error : '',
			
			show_login_form : false,
			
			contentConfig : null,
			styleConfig : null,
		};
	}
	
	componentDidMount(){
		var self = this;
		ApiClient.get('/api/v1/get_page?type=login', (err, reply) => {
			if(!err){
				self.setState({
					styleConfig : reply.styleConfig,
					contentConfig : reply.contentConfig
				});
			}
		});
		
		var pageStyle = {
	    	
	    	sections : {
		    	
		    	// Header
		    	'Header' : {
			    	
			    	style : {
				    	backgroundColor : '#fff',
						height : '260px',
						boxSizing : 'border-box',
						borderBottom : '2px solid #ddd',
			    	},
			    	
			    	items : {
				    	'Logo' : {
					    	style : {
						    	borderRadius : '10px',
								height : '75%',
								width : 'inherit',
								margin : '0px',
								marginTop : '30px'
					    	}
				    	}
			    	}
		    	},
		    	
		    	// Request form
		    	'RequestForm' : {
			    	style : {
				    	marginTop : '40px',
						backgroundColor : '#fff',
						borderWidth : '2px',
						borderColor : '#f5a623',
						color : '#222',
						padding : '20px',
						borderRadius : '10px',
			    	},
			    	
			    	items : {
				    	
				    	'EmailLabel' : {
					    	style : {
						    	color : '#222'
					    	},
					    	content : {
						    	value : 'Email'
						    }
				    	},
				    	
				    	'EmailInput' : {
					    	style : {
						    	color : '#222',
								background : '#f6f6f6',
								border : '1px solid #ddd'
					    	}
				    	},
				    	
				    	'SubmitButton' : {
					    	style : {
						    	backgroundColor : '#222',
								color : '#fff',
								
								'&:hover' : {
									backgroundColor : '#222',
									color : '#fff',
								}
					    	},
					    	
					    	content : {
						    	value : 'Request login'
						    }
				    	}
			    	}
		    	},
		    	
		    	// Login form
		    	'LoginForm' : {
			    	style : {
				    	marginTop : '20px',
			    	}
		    	}
	    	}
	    		
    	};
    	
    	var css = '';
    	
    	// Sections
    	_.each(Object.keys(pageStyle.sections), (sectionKey) => {
	    	
	    	css += '.'+sectionKey+' {\n'
	    	
	    	// Section -> style
	    	_.each(Object.keys(pageStyle.sections[sectionKey].style), (attribute) => {
		    	css += '\t'+camelCaseToDash(attribute)+':'+pageStyle.sections[sectionKey].style[attribute]+';\n';
	    	});
	    	
	    	css += '}\n\n';
	    	
	    	// Section -> items
	    	if(pageStyle.sections[sectionKey].items){
		    	_.each(Object.keys(pageStyle.sections[sectionKey].items), (itemKey) => {
			    	
			    	css +=  '.'+sectionKey+' .'+itemKey+' {\n'
			    	
			    	// Item -> style
					_.each(Object.keys(pageStyle.sections[sectionKey].items[itemKey].style), (itemAttribute) => {
						css += '\t'+camelCaseToDash(itemAttribute)+':'+pageStyle.sections[sectionKey].items[itemKey].style[itemAttribute]+';\n';
					});
					
					css += '}\n\n';
			    });
		    }
    	});
    	
    	console.log(css);
    	
    	//$('head').append('<style>'+css+'</style>');
	}
	
	onRequestLogin(e){
		var self = this;
		
		e.preventDefault();
		
		if(!self.state.is_requesting_password){
			self.setState({ is_requesting_password : true });
			
			ApiClient.post('/api/v1/request_credentials', {
				email : $(e.currentTarget).find("input[name='email']").val()
			}, (err, reply) => {
				//console.log(err, reply);
				
				if(reply.status === 'error'){
					self.setState({ request_login_error : reply.code, is_requesting_password : false });
				} else {
					self.setState({ request_login_success : true, is_requesting_password : false });
				}
			});
		}
	}
	
	onLogin(e){
		var self = this;
		
		e.preventDefault();
		
		var email = $(e.currentTarget).find("input[name='email']").val();
		var password = $(e.currentTarget).find("input[name='password']").val();
		
		ApiClient.post('/api/v1/verify_credentials', {
			email : email,
			password : password
		}, (err, reply) => {
			//console.log(err, reply);
			
			if(reply.status === 'error'){
				self.setState({ login_error : reply.code });
			}
			
			if(reply.status === 'ok'){
				window.location.href = reply.profile_url;
			}
		});
	}
	
	render(){
    	
    	var self = this;
    	
    	/*
    	if(!self.state.styleConfig || !self.state.contentConfig){
	    	return (<div>
    		
				Loading...
				    		
			</div>);
    	}
    	
    	var styleConfig = _.extend({}, self.state.styleConfig);
    	
    	var headerSpacer = null;
    	
    	if(styleConfig._fixedHeader){		    
		    headerSpacer = (<div style={{height:styleConfig.header.height}}></div>)
    	}*/

    	var pageClasses = "login-page-wrapper";
    	var request_login_form_el = '';
    	var request_login_error_el = '';
    	var login_form_el = '';
    	var login_error_el = '';
    	var show_login_form_button_el = (<span className="show-login-form-button" onClick={() => {
	    	self.setState({ show_login_form : true });
    	}}>Have a password? click here</span>);
    	
    	if(self.state.request_login_error !== ''){
	    	request_login_error_el = (<div className="error">{self.state.request_login_error}</div>);
    	}
    	
    	if(self.state.login_error !== ''){
	    	login_error_el = (<div className="error">{self.state.login_error}</div>);
    	}
    	
    	if(self.state.request_login_success){
	    	request_login_form_el = (<div className="request-login-form">
					
				Login successfully requested, your should receive a email momentarily, be sure to also check your [SPAM] folder.
			</div>);
    	} else {
    		
    		var submit_button = "";
    		
    		if(!self.state.is_requesting_password){
	    		submit_button = (<input 
	    			type="submit" 
	    			className="common-login-button SubmitButton" 
	    			value="Request login" 
	    			/*style={styleConfig.requestForm.submitButton} 
					onMouseOver = {(e) => Object.keys(styleConfig.requestForm.submitButton['&:hover']).map((key) => {
						return e.target.style[key] = styleConfig.requestForm.submitButton['&:hover'][key];
					})}
					onMouseOut = {(e) => Object.keys(styleConfig.requestForm.submitButton).map((key) => {
						return e.target.style[key] = styleConfig.requestForm.submitButton[key];
					})}*/
	    		/>);
    		} else {
	    		submit_button = (<div className="submit-loading"><i className="icon fa fa-spinner fa-spin"></i> Requesting...</div>);
    		}
    		
    		// Request form
	    	request_login_form_el = (<form 
	    		className="request-login-form RequestForm" 
	    		onSubmit={self.onRequestLogin.bind(self)} 
	    		//style={styleConfig.requestForm}
	    	>
					
				{request_login_error_el}
					
				<label /*style={styleConfig.requestForm.emailLabel}*/>Email</label>
				<input /*style={styleConfig.requestForm.emailInput}*/ type="text" className="common-input" name="email" />
					
				{submit_button}
				
			</form>);
    	}
    	
    	
    	if(self.state.show_login_form){
	    	show_login_form_button_el = null;
	    	pageClasses += " has_login_form";
	    	
	    	// Login form
	    	login_form_el = (<form className="login-form LoginForm" onSubmit={self.onLogin.bind(self)} /*style={styleConfig.loginForm}*/>
					
				{login_error_el}
				
				<label>Email</label>
				<input type="text" className="common-input" name="email" />
				
				<label>Password</label>
				<input type="password" className="common-input" name="password" />
				
				<input type="submit" className="common-save-button" value="Login" />
			</form>);
    	}
    	
    	return (<div className={pageClasses} /*style={styleConfig.body}*/>
    		
    		<div className="main-header Header" /*style={styleConfig.header}*/>
				<img className="logo Logo" src="logo" /*style={styleConfig.header.logo}*/ />
			</div>
			
			{/*headerSpacer*/}
			
			<div className="main-container">
				
				{request_login_form_el}
				
				{login_form_el}
			
				{show_login_form_button_el}

			</div>
			    		
    	</div>);
	}
}

module.exports = App;