
var _ = require('underscore');
var $ = require('jquery');

var ApiClient = function(){
		
	var self = this;
	
	this.host = '';
	
	// GET
	this.get = function(endpoint, callback){
		$.ajax({
			url: self.host+endpoint,
			type: 'get',
			dataType: 'json',
			error: function(err){
				if(!_.isUndefined(callback)){
					callback(err.statusText);
				}
			},
			success: function(response){
				if(!_.isUndefined(callback)){
					callback(null, response);
				}
			}
		});	
	}
	
	// POST
	this.post = function(endpoint, data, callback){
		$.ajax({
			url: self.host+endpoint,
			type: 'post',
			dataType: 'json',
			data : data,
			error: function(err){
				if(!_.isUndefined(callback)){
					callback(err.statusText);
				}
			},
			success: function(response){
				if(!_.isUndefined(callback)){
					callback(null, response);
				}
			}
		});	
	}
};
 	

module.exports = new ApiClient;